module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-theme-event/gatsby-browser.js'),
      options: {"plugins":[],"rootPath":"/","homePath":"/producers","exhibitorBase":"partners","producerBase":"producers","productBase":"products","withOverlay":false,"showCountryFilter":true,"landingPageSort":{"fields":["contentLevel","sortName"],"order":["DESC","ASC"]},"exhibitorSort":{"fields":["sortName","name","contentLevel","sortName"],"order":["ASC","ASC","DESC","ASC"]},"producerSort":{"fields":["event___sortOrder","stand___sortOrder","contentLevel","sortName"],"order":["ASC","ASC","DESC","ASC"]},"productSort":{"fields":["priceNumeric","shortName","vintage"],"order":["DESC","ASC","DESC"]},"locale":"en","eventId":"5f8d3bae2307133b5b0e3d4f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.0.27","publicApiKey":"ZDk1OWU3OGEtOTczOC00NTUyLWFiYTAtOTg1MTRiNDc2YzczNjM3NDIzMTY3OTMyODI3MzE0","currency":"gbp","defaultLang":"en","innerHTML":"<address-fields section=\"top\"><div><div class=\"snipcart-form__row\"><div class=\"snipcart-form__field snipcart-form__cell--large\"><snipcart-label for=\"company\">Company name</snipcart-label><snipcart-input name=\"company\"></snipcart-input></div></div><div class=\"snipcart-form__row\"><div class=\"snipcart-form__field snipcart-form__cell--large\"><snipcart-label for=\"phone\">Phone number</snipcart-label><snipcart-input name=\"phone\"></snipcart-input></div></div></div></address-fields>"},
    }]
